/***
 *
 *   PROFILE
 *   Update the user profile or close the account
 *
 **********/

import React, { Fragment, useContext } from 'react'
import {
  AuthContext,
  ViewContext,
  Form,
  Card,
  AccountNav,
  Button,
  useNavigate,
  Animate,
  useAPI,
  Event,
} from 'components/lib'
import { regions } from '@lawcyborg/packages'

export function Profile(props) {
  const navigate = useNavigate()

  // context
  const authContext = useContext(AuthContext)
  const viewContext = useContext(ViewContext)

  // fetch
  const user = useAPI('/api/user')

  function closeAccount() {
    let hasExecuted = false
    viewContext.modal.show(
      {
        title: 'Close Your Account',
        form: {},
        buttonText: 'Close Account',
        url: authContext.permission.owner ? '/api/account' : '/api/user',
        method: 'DELETE',
        destructive: true,
        text:
          'Are you sure you want to delete your account? ' +
          'You will lose all of your data and this can not be undone.',
      },
      () => {
        if (hasExecuted) return
        hasExecuted = true
        // destory user
        Event('closed_account', { email: user?.data?.email })
        localStorage.clear()
        navigate('/signup')
      }
    )
  }

  return (
    <Fragment>
      <AccountNav />
      <Animate>
        <Card title="Edit Your Profile" loading={user.loading} restrictWidth>
          {user?.data && (
            <Form
              buttonText="Save"
              url="/api/user"
              method="PATCH"
              inputs={{
                name: {
                  label: 'Your Name',
                  type: 'text',
                  required: true,
                  value: user.data.name,
                  errorMessage: 'Please enter your name',
                },
                email: {
                  label: 'Email address',
                  type: 'email',
                  required: true,
                  value: user.data.email,
                  errorMessage: 'Please enter your email address',
                },
                ...(user.data.permission === 'owner' && {
                  account_name: {
                    type: 'text',
                    label: 'Organisation',
                    value: user.data.account_name,
                  },
                }),
                ...(user.data.permission === 'owner' && {
                  region: {
                    type: 'select',
                    label: 'Region',
                    default: user.data.region,
                    value: user.data.region,
                    options: Object.values(regions)?.[0].map((region) => ({
                      name: region,
                      label: region,
                    })),
                  },
                }),
                ...(user.data.permission === 'owner' && {
                  contact_number: {
                    type: 'text',
                    label: 'Contact Number',
                    pattern: /^(?:\+?642|02)\d{7,11}$/g,
                    value: user.data.contact_number,
                    errorMessage: 'Please enter valid contact number starting with +642 or +02',
                  },
                }),
                ...(user.data.accounts?.length > 1 && {
                  default_account: {
                    label: 'Default Account',
                    type: 'select',
                    default: user.data.default_account,
                    options: user.data.accounts.map((x) => {
                      return {
                        value: x.id,
                        label: x.name,
                      }
                    }),
                  },
                }),
              }}
              callback={(res) => {
                // update the account name
                if (authContext.user?.accounts?.length > 0) {
                  const accounts = [...authContext.user.accounts]
                  accounts[accounts.findIndex((x) => x.id === authContext.user.account_id)].name =
                    res.data.data.account_name
                  authContext.update({ accounts: accounts })
                }

                // update the user name
                authContext.update({ name: res.data.data.name })
                Event('account_updated', { ...res.data.data, user_id: authContext.user.user_id })
              }}
            />
          )}

          <Fragment>
            <br />
            <Button textOnly action={closeAccount} text="Close Your Account" />
          </Fragment>
        </Card>
      </Animate>
    </Fragment>
  )
}
